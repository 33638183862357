import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { TextSm } from '@system'
import prepareDangerouslySetHTML from '@helpers/prepare-dangerously-set-html'

const useStyles = makeStyles((theme) => ({
  resources: {
    paddingBottom: '40px',
  },
  resourcesTitle: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    paddingBottom: '12px',
  },
  resourceLink: {
    paddingTop: '12px',
    '& p': {
      paddingBottom: '12px',
      fontWeight: theme.typography.fontWeightMedium,
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
    },
  },
}))

const Resources = (props) => {
  const classes = useStyles()
  const articles = props.articles
  const whitepaper = props.whitepaper
  return (
    <>
      {articles && articles.length > 0 && (
        <Box className={classes.resources}>
          <TextSm className={classes.resourcesTitle}>Resources:</TextSm>
          <Box className={classes.resourceLink}>
            {articles.map((article) => (
              <TextSm key={article._uid}>
                <a
                  href={article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  dangerouslySetInnerHTML={prepareDangerouslySetHTML(
                    article.title
                  )}
                ></a>
              </TextSm>
            ))}
            {whitepaper && (
              <TextSm>
                <a
                  href={whitepaper.pdf_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {whitepaper.description.slice(0, 100) + '...'}
                </a>
              </TextSm>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default Resources
