import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Tabs, Tab, Box } from '@material-ui/core'
import { TextSm, H6 } from '@system'
import prepareDangerouslySetHTML from '@helpers/prepare-dangerously-set-html'
import PartnerDetailVideoScreenshots from './partner-detail-video-screenshots'
import Resources from './resources'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => {
  return {
    text: {
      paddingBottom: '20px',
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightRegular,
      '& span': {
        color: `${theme.palette.text.tertiary} !important`,
        fontSize: `${theme.typography.body2.fontSize} !important`,
      },
      '& p': {
        fontSize: `${theme.typography.body2.fontSize} !important`,
      },
      '& ul': {
        paddingLeft: '15px',
        listStyleType: 'none',

        padding: '0px',
        '& li': {
          position: 'relative',
          paddingLeft: '13px',
          '&:before': {
            content: '""',
            position: 'absolute',
            height: '4px',
            width: '4px',
            borderRadius: '50%',
            backgroundColor: theme.palette.secondary.main,
            left: '0px',
            top: '8px',
          },
        },
      },
    },
    title: {
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightLight,
      paddingBottom: '40px',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: 5,
    },
    container: {
      maxWidth: '1056px',
      width: '100%',
      height: '48px',
    },
    outerContainer: {
      maxWidth: '1440px',
      mx: 'auto',
      width: '100%',
    },
    worksWith: {
      marginTop: '20px',
      '& span': {
        fontWeight: theme.typography.fontWeightLight,
      },
      '& span:first-child': {
        paddingLeft: '7px',
      },
    },
    innerContainer: {
      padding: '20px 0px',
    },
    productColor: {
      color: theme.palette.primary.main,
      paddingLeft: '5px',
    },
    tabIndicator: {
      backgroundColor: theme.palette.primary.main,
      height: '5px',
    },
  }
})

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const TabIntegrations = (props) => {
  const [value, setValue] = useState(0)
  const classes = useStyles()

  const mutlipleBuiltFor =
    props.tabs &&
    props.tabs
      .map((tab) => tab['built_for'])
      .map((tab, i, final) => final.indexOf(tab) !== i && i)
      .filter((obj) => props.tabs[obj])
      .map((tab) => props.tabs[tab]['built_for']).length > 0
      ? true
      : false

  const addVideoThumbnailToScreenshotsList = (
    screenshots,
    partnerDetailVideoId
  ) => {
    if (partnerDetailVideoId) {
      if (screenshots) {
        return [...partnerDetailVideoId, ...screenshots] //add thumbnail for video at starting index
      }
      return [...partnerDetailVideoId]
    }
    return screenshots
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const priorityOrder = {
    Build: 1,
    BuildingConnected: 2,
    PlanGrid: 3,
    'BIM 360': 4,
    'Partner Card': 5,
  }
  const sortedTabs = [...props.tabs].sort((a, b) => {
    return priorityOrder[a.built_for] - priorityOrder[b.built_for]
  })

  return (
    <Box>
      <Box className={classes.container} mx="auto" px={4.2}>
        {props && props.tabs && props.tabs.length > 1 ? (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            inkBarStyle={{ background: 'pink' }}
            classes={{
              indicator: classes.tabIndicator,
            }}
          >
            {sortedTabs.map((tab, index) => {
              const tabLabel =
                tab.built_for === 'Plan Grid'
                  ? tab.built_for.replace(/\s/g, '')
                  : tab.built_for
              return (
                tab.built_for && (
                  <Tab
                    label={mutlipleBuiltFor ? tabLabel : tabLabel}
                    {...a11yProps(index)}
                  />
                )
              )
            })}
          </Tabs>
        ) : (
          <Box className={classes.innerContainer}>
            <H6>
              Integration for
              <span className={classes.productColor}>
                {get(props, 'tabs[0].built_for')}
              </span>
            </H6>
          </Box>
        )}
      </Box>
      <Box className={classes.outerContainer}>
        <Box className={classes.tabItems}>
          {sortedTabs &&
            sortedTabs &&
            sortedTabs.map((tab, index) => (
              <TabPanel value={value} key={tab._uid} index={index}>
                <PartnerDetailVideoScreenshots
                  screenshots={addVideoThumbnailToScreenshotsList(
                    tab.screenshots,
                    tab.video
                  )}
                />
                {tab.works_with && tab.works_with.length > 0 && (
                  <H6 className={classes.worksWith}>
                    Works with:
                    {tab.works_with.map((worksWith, key) => (
                      <span key={worksWith}>
                        {worksWith}
                        {key !== props.tabs[index].works_with.length - 1
                          ? ', '
                          : ''}
                      </span>
                    ))}
                  </H6>
                )}

                <Box className={classes.innerContainer}>
                  <H6>The Integration</H6>
                  <TextSm
                    className={classes.text}
                    dangerouslySetInnerHTML={prepareDangerouslySetHTML(
                      tab.solution_description
                    )}
                  />
                  <TextSm
                    className={classes.text}
                    dangerouslySetInnerHTML={prepareDangerouslySetHTML(
                      tab.product_description
                    )}
                  />
                  <H6>Details</H6>
                  <TextSm className={classes.text}>
                    <ul>
                      {tab.benefits.map((benefit, index) => (
                        <li key={benefit._uid}> {benefit} </li>
                      ))}
                    </ul>
                  </TextSm>
                </Box>
              </TabPanel>
            ))}
        </Box>
      </Box>

      <Resources articles={props.articles} whitepaper={props.whitepaper} />
    </Box>
  )
}

export default TabIntegrations
