import React from 'react'
import { graphql } from 'gatsby'
import { PageContextProvider } from '@context'
import TopNav from '@blok/top-nav'
import BottomNav from '@blok/bottom-nav'
import IntegrationDetails from '../components/integrations/integration-details'
import PageContextProfiler from '@helpers/page-context-profiler'

const IntegrationDetailsEntry = ({ data, location, pageContext }) => {
  const integrationContent = pageContext.data

  const prepareStory = () => {
    const bottomNav = Object.assign({}, data.bottomNav)
    const marketoFormModule = Object.assign({}, data.marketoFormModule)
    const story = Object.assign({}, pageContext.data)
    const topNav = Object.assign({}, data.topNav)

    bottomNav.content = JSON.parse(bottomNav.content)
    marketoFormModule.content = JSON.parse(marketoFormModule.content)
    topNav.content = JSON.parse(topNav.content)

    return { bottomNav, marketoFormModule, story, topNav }
  }

  let {
    bottomNav: { content: bottomNav },
    marketoFormModule: { content: marketoFormModule },
    story: { content, ...otherProps },
    topNav: { content: topNav },
  } = prepareStory()

  return (
    <PageContextProvider {...otherProps} location={location}>
      {process.env.NODE_ENV !== 'production' && (
        <PageContextProfiler pageContext={pageContext} />
      )}
      {topNav && <TopNav blok={topNav}></TopNav>}
      <IntegrationDetails
        companyDetails={integrationContent}
        marketoFormModule={marketoFormModule}
      />
      {bottomNav && <BottomNav blok={bottomNav}></BottomNav>}
    </PageContextProvider>
  )
}

export default IntegrationDetailsEntry

export const query = graphql`
  query (
    $marketoFormModuleId: String!
    $topNavId: String!
    $bottomNavId: String!
  ) {
    topNav: storyblokEntry(id: { eq: $topNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    bottomNav: storyblokEntry(id: { eq: $bottomNavId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
    marketoFormModule: storyblokEntry(id: { eq: $marketoFormModuleId }) {
      id
      name
      created_at
      uuid
      slug
      field_component
      full_slug
      lang
      content
      is_startpage
      parent_id
      group_id
      first_published_at
      alternates {
        full_slug
        id
        name
        parent_id
        slug
        published
        is_folder
      }
    }
  }
`
