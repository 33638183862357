import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { Container, Box, Grid } from '@material-ui/core'
import H1 from '@system/h1'
import ACC_THEME from '../../themes'
import QuoteModule from './quote-module'
import G2Reviews from './g2-reviews'
import { TextSm } from '@system'
import renderBloks from '@renderBloks'
import convertUrlToHttps from '@helpers/convert-url-to-https'
import prepareDangerouslySetHTML from '@helpers/prepare-dangerously-set-html'
import TabsIntegrations from './tab-integrations'
import Support from './support'
import Layout from '../layout'
import get from 'lodash/get'
import PageContainer from '@system/page-container'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: ACC_THEME.palette.common.white,
    paddingTop: '60px',
  },
  eyebrow: {
    fontSize: '1.5rem',
    paddingBottom: '1rem',
  },
  logo: {
    width: '130px',
    '& img': {
      height: 'auto',
      maxWidth: '130px',
      objectFit: 'contain',
    },
  },
  outerContainer: {
    color: ACC_THEME.palette.text.primary,
    fontWeight: ACC_THEME.typography.fontWeightLight,
    textAlign: 'left',
    [ACC_THEME.breakpoints.down('sm')]: {
      '& > div:first-child': {
        paddingRight: '0 !important',
      },
      '& > div:last-child': {
        paddingLeft: '0 !important',
      },
    },
    [ACC_THEME.breakpoints.between('sm', 'md')]: {
      '& > div:first-child': {
        paddingRight: '40px !important',
      },
      '& > div:last-child': {
        paddingLeft: '0 !important',
      },
    },
  },
  tabs: {
    backgroundColor: ACC_THEME.palette.common.white,
    textAlign: 'left',
  },
  form: {
    backgroundColor: ACC_THEME.palette.background.slite,
    padding: '25px',
    marginBottom: '50px',
    '& h4': {
      marginBottom: '0px !important',
    },
    '& div': {
      width: '100% !important',
    },
  },
  leftGrid: {
    padding: '40px 40px 0 0',
  },
  rightGrid: {
    padding: '40px 0 0 40px',
  },
  topGrid: {
    marginTop: '10px',
  },
}))

const IntegrationDetails = (props) => {
  const { companyDetails } = props
  const classes = useStyles()

  const hasMultipleCompanyIntegrations =
    companyDetails && companyDetails.length > 0
  const logo = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].logo')
    : !!companyDetails && companyDetails.logo
  const integrationsACC = 'Integration | Autodesk Construction Cloud'

  const companyName = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].name')
    : !!companyDetails && companyDetails.name

  const metaData = {
    description: hasMultipleCompanyIntegrations
      ? get(props, 'companyDetails[0].meta_description')
      : !!companyDetails && companyDetails.meta_description,
    title: `${companyName} ${integrationsACC}`,
  }
  const isG2Reviews = get(props, 'companyDetails[0].g2reviews')
  const isSocialProofsNull =
    get(props, 'companyDetails[0].social_proofs') === null

  const g2Reviews = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].g2reviews')
    : !!companyDetails && companyDetails.g2reviews

  const companyDescription = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].company.description')
    : !!companyDetails && companyDetails.company.description

  const articles = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].articles')
    : !!companyDetails && companyDetails.articles

  const whitepaper = hasMultipleCompanyIntegrations
    ? get(props, 'companyDetails[0].whitepaper')
    : !!companyDetails && companyDetails.whitepaper

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <PageContainer pt={6}>
          <Container fixed>
            {!!companyName && (
              <H1
                className={classes.eyebrow}
              >{`${companyName} Integration`}</H1>
            )}
            <Box className={classes.logo}>
              <img src={convertUrlToHttps(logo)} alt={companyName} />
            </Box>
            <Grid container className={classes.outerContainer}>
              <Grid item xs={12} sm={7} className={classes.leftGrid}>
                <Box className={classes.description}>
                  <TextSm
                    component="div"
                    dangerouslySetInnerHTML={prepareDangerouslySetHTML(
                      companyDescription
                    )}
                  />
                </Box>
                <Box className={classes.topGrid}>
                  <TabsIntegrations
                    tabs={companyDetails}
                    articles={articles}
                    whitepaper={whitepaper}
                  />
                </Box>
                {isG2Reviews && isSocialProofsNull && (
                  <G2Reviews
                    name={companyName}
                    reviews={g2Reviews}
                    maxWidth="100%"
                    paddingTop="10px"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={5} className={classes.rightGrid}>
                <Box>
                  <Support
                    support={
                      hasMultipleCompanyIntegrations
                        ? get(props, 'companyDetails[0]')
                        : companyDetails
                    }
                  />
                  <Box className={classes.form}>
                    {renderBloks([props.marketoFormModule], {
                      isSubmitButtonVisible: true,
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <QuoteModule allProducts={get(props, 'companyDetails[0]')} />
          {isG2Reviews && !isSocialProofsNull && (
            <Container fixed>
              <G2Reviews name={companyName} reviews={g2Reviews} />
            </Container>
          )}
        </PageContainer>
      </ThemeProvider>
    </Layout>
  )
}
export default IntegrationDetails
