import React, { useState } from 'react'
import { H3, H6 } from '@system'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import prepareDangerouslySetHTML from '@helpers/prepare-dangerously-set-html'
import Icon from '@system/icon'

const useStyle = makeStyles((theme) => ({
  inactiveSlide: {
    display: 'none',
  },
  activeSlide: {
    display: 'block',
  },
  quote: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  carouselArrow: {
    position: 'absolute',
    top: '50%',
    display: 'block',
    color: theme.palette.common.white,
    cursor: 'pointer',
    opacity: 0.75,
    transition: 'opacity .15s cubic-bezier(.4, 0, 1, 1)',
    transform: 'translateY(-50%)',
    '&:focus': {
      outline: 0,
    },
    '&:hover': {
      opacity: 0.5,
    },
  },
  carouselArrowLeft: {
    left: '0px',
  },
  carouselArrowRight: {
    right: '0px',
  },
  carouselIndicators: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '20px',
    listStyleType: 'none',
    '& li': {
      marginTop: '2rem',
      marginLeft: '9px',
      '& a': {
        height: '7px',
        width: '7px',
        display: 'block',
        borderRadius: '50%',
        backgroundColor: 'white',
        margin: '0px 6px',
        opacity: 0.5,
        '&:hover': {
          opacity: 1,
        },
      },
    },
  },
  quoteIndicator: {
    display: 'block',
    width: '24px',
    height: '3px',
    cursor: 'pointer',
    opacity: '1 !important',
    transition: 'opacity .15s cubic-bezier(.4, 0, 1, 1)',
  },
  inactiveQuoteIndicator: {
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      opacity: '0.5  !important',
    },
  },
  activeQuoteIndicator: {
    backgroundColor: theme.palette.legacy.secondary,
  },
  carouselArrowDimension: {
    width: '3em',
    height: '3em',
  },
  nameAttribute: {
    display: 'block',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const QuoteCarousel = (props) => {
  const classes = useStyle()
  const [activeSlide, setActiveSlide] = useState(0)

  const goToSlide = (index) => {
    setActiveSlide(index)
  }

  const goToPrevSlide = (e) => {
    e.preventDefault()

    let index = activeSlide
    let numberOfSlides = props.data.length

    if (index < 1) {
      index = numberOfSlides
    }

    --index

    setActiveSlide(index)
  }

  const goToNextSlide = (e) => {
    e.preventDefault()

    let index = activeSlide
    let numberOfSlides = props.data.length - 1

    if (index === numberOfSlides) {
      index = -1
    }

    ++index

    setActiveSlide(index)
  }

  const CarouselLeftArrow = (props) => {
    return (
      <span
        className={classNames(classes.carouselArrow, classes.carouselArrowLeft)}
        onClick={props.onClick}
      >
        <Icon styles={classes.carouselArrowDimension}>ChevronLeft</Icon>
      </span>
    )
  }

  const CarouselRightArrow = (props) => {
    return (
      <span
        className={classNames(
          classes.carouselArrow,
          classes.carouselArrowRight
        )}
        onClick={props.onClick}
      >
        <Icon styles={classes.carouselArrowDimension}>ChevronRight</Icon>
      </span>
    )
  }

  const CarouselIndicator = (props) => {
    return (
      <li>
        <span
          className={classNames(classes.quoteIndicator, {
            [classes.inactiveQuoteIndicator]: props.index !== props.activeIndex,
            [classes.activeQuoteIndicator]: props.index === props.activeIndex,
          })}
          onClick={props.onClick}
        />
      </li>
    )
  }

  const CarouselSlides = (props) => {
    const { name } = props.slide
    return (
      <Box
        className={classNames(classes.inactiveSlide, {
          [classes.activeSlide]: props.activeIndex === props.index,
        })}
      >
        <H3
          className={classes.quote}
          dangerouslySetInnerHTML={prepareDangerouslySetHTML(
            `${props.slide.quote}`
          )}
        />
        {name && <H6 className={classes.nameAttribute}>- {name}</H6>}
      </Box>
    )
  }
  return (
    <Box>
      <CarouselLeftArrow onClick={(e) => goToPrevSlide(e)} />
      {props.data.map((slide, index) => {
        return (
          <CarouselSlides
            key={slide._uid}
            index={index}
            activeIndex={activeSlide}
            slide={slide}
          />
        )
      })}
      <CarouselRightArrow onClick={(e) => goToNextSlide(e)} />

      <Box className={classes.carouselIndicators}>
        {props.data.map((slide, index) => {
          return (
            <CarouselIndicator
              key={slide._uid}
              index={index}
              activeIndex={activeSlide}
              isActive={activeSlide === index}
              onClick={(e) => goToSlide(index)}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default QuoteCarousel
