import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import quoteImage from '../../images/quote-marks.svg'
import QuoteCarousel from './quote-carousel'
import prepareDangerouslySetHTML from '@helpers/prepare-dangerously-set-html'
import { H3, H6 } from '@system'
import get from 'lodash/get'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.secondary.main,
    padding: '60px 0px',
    position: 'relative',
    minHeight: '400px',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    objectFit: 'contain',
    width: 90,
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      width: 70,
      marginBottom: '1.5rem',
    },
  },
  quote: {
    display: 'block',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  nameAttribute: {
    display: 'block',
    color: theme.palette.common.white,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const QuoteModule = (props) => {
  const classes = useStyles()
  const {
    allProducts: { social_proofs },
  } = props
  return (
    <SbEditable content={props.allProducts}>
      {social_proofs && (
        <Box className={classes.background}>
          <ContentContainer>
            <Box display="flex" justifyContent="center">
              <img
                src={quoteImage}
                className={classes.image}
                alt="Quotation mark"
              ></img>
            </Box>
            {social_proofs.length > 1 ? (
              <QuoteCarousel data={social_proofs} autoPlayTime={4000} />
            ) : (
              <>
                <H3
                  className={classes.quote}
                  dangerouslySetInnerHTML={prepareDangerouslySetHTML(
                    `${get(props, 'allProducts.social_proofs[0].quote')}`
                  )}
                />
                <H6 className={classes.nameAttribute}>
                  - {get(props, 'allProducts.social_proofs[0].name')}
                </H6>
              </>
            )}
          </ContentContainer>
        </Box>
      )}
    </SbEditable>
  )
}
export default QuoteModule
