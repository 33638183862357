import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import convertUrlToHttps from '@helpers/convert-url-to-https'

const useStyles = makeStyles((theme) => ({
  screenshotView: {
    height: '100%',
    backgroundColor: '#f1f2f2',
    overflow: 'hidden',
    padding: '1px',
    '& img': {
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      cursor: 'pointer',
      height: '100%',
    },
  },
  screenshotCol: {
    width: 'calc(20% - 10px)',
    marginBottom: '10px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  screenshot: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-5px',
    marginRight: '-5px',
  },
}))
const PartnerDetailScreenshots = (props) => {
  const { screenshots, toggleScreenshotPreview } = props

  const classes = useStyles(props)

  return (
    <Box className={classes.screenshot}>
      {screenshots &&
        screenshots.map((screenshot, index) => (
          <Box className={classes.screenshotCol} key={screenshot._uid}>
            <Box className={classes.screenshotView}>
              <img
                src={convertUrlToHttps(
                  `${screenshot.url_poster || screenshot.url}?w=120`
                )}
                onClick={() =>
                  toggleScreenshotPreview(
                    screenshot.url_video
                      ? { url_video: screenshot.url_video }
                      : { url: screenshot.url }
                  )
                }
                alt={
                  screenshot.title &&
                  screenshot.title.substr(0, screenshot.title.lastIndexOf('.'))
                }
              />
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default PartnerDetailScreenshots
