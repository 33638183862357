import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { Text, TextSm } from '@system'
import Rating from '@material-ui/lab/Rating'
import { Button } from '@system'
import Icon from '@system/icon'

const useStyles = makeStyles((theme) => ({
  outerContaner: {
    color: theme.palette.text.tertiary,
    maxWidth: (props) => props.maxWidth || '58.33%',
    paddingTop: (props) => props.paddingTop || '40px',
    paddingBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  header: {
    color: theme.palette.text.tertiary,
    paddingBottom: '5px',
    fontSize: '22px',
    '& a': {
      paddingRight: '5px',
    },
    '& img': {
      color: theme.palette.text.tertiary,
      width: '22px',
      height: 'auto',
      transform: 'translateY(4px)',
    },
  },
  g2Message: {
    color: theme.palette.text.tertiary,
    fontSize: theme.typography.button.fontSize,
  },
  subHeader: {
    paddingBottom: '15px',
    color: theme.palette.text.tertiary,
    '& > span:first-child': {
      transform: 'translateY(5px)',
      marginRight: '10px',
    },
    '& a': {
      paddingRight: '3px',
    },
  },
  reviewLink: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
  answersHeader: {
    display: 'flex',
    paddingBottom: '5px',
    fontWeight: theme.typography.fontWeightBold,
    '& > span': {
      transform: 'translateY(-5px)',
      marginLeft: '15px',
    },
  },
  answersTitle: {
    display: 'block',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  answerTitle: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: '3px',
  },
  answerValue: {
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  answers: {
    paddingBottom: '20px',
    '& a': {
      marginLeft: '30px',
      textDecoration: 'none',
      position: 'relative',
      top: '-10px',
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        borderBottom: `1.5px solid ${theme.palette.text.tertiary}`,
      },
    },
    '& svg': {
      transform: 'translateY(6px)',
    },
  },
  answersSubHeader: {
    display: 'flex',
    paddingBottom: '8px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  userDetails: {
    display: 'flex',
    paddingRight: '20px',
  },
  company: {
    paddingLeft: '7px',
    paddingRight: '10px',
  },
  answer: {
    paddingLeft: '30px',
    paddingBottom: '10px',
  },
  readMore: {
    display: 'flex',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
      '& img': {
        color: theme.palette.text.tertiary,
        width: '22px',
        height: 'auto',
        marginLeft: '2px',
        transform: 'translateY(4px)',
      },
    },
  },
  writeReviewButton: {
    paddingTop: '40px',
  },
  buttonContainer: {
    paddingBottom: '13px',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      ...theme.typography.button,
      color: theme.palette.text.white,
      background: theme.palette.primary.main,
      fontSize: '13px',
      boxShadow: 'none',
      padding: '10px 16px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  infoText: {
    fontSize: theme.typography.button.fontSize,
    color: theme.palette.text.tertiary,
  },
}))

const G2Reviews = (props) => {
  const classes = useStyles(props)
  const { crowd_url, rating, reviews, write_review_url } = props.reviews
  const [toggleShow, setToggleShow] = useState({})

  return (
    <>
      {reviews && (
        <Box className={classes.outerContaner}>
          <Box className={classes.header}>
            <a
              className="x-g2-link-home"
              href="https://www.g2.com"
              title="G2 Crowd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src=" https://tracking.g2crowd.com/logo/dark"
                alt="G2 Crowd"
                title="G2 Crowd"
              />
            </a>
            Reviews
          </Box>
          <Box className={classes.g2Message}>
            By clicking on the link below, you will be redirected to the product
            review webpage on G2 Crowd to write a review of our integration
            partner. By submitting a review to G2 Crowd, you consent to the
            display of your review on Autodesk’s webpages. If you choose to
            submit an a review under your name or username, you consent to the
            display of your name or username on Autodesk’s webpages.
          </Box>
          <Box className={classes.subHeader}>
            <Rating name="read-only" value={rating} precision={0.5} readOnly />
            <span>
              <a
                href={write_review_url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.reviewLink}
              >
                Click here
              </a>
              to write a review!
            </span>
          </Box>
          <Box>
            {reviews.map((review) => (
              <>
                <Box key={review.uid} className={classes.answers}>
                  <Box className={classes.answersHeader}>
                    <Text className={classes.answersTitle}>{review.title}</Text>
                    <Rating
                      name="read-only"
                      value={review.rating}
                      precision={0.5}
                      size="small"
                      readOnly
                    />
                  </Box>
                  <Box className={classes.answersSubHeader}>
                    <Box className={classes.userDetails}>
                      <TextSm>{review.user.name}</TextSm>
                      {review.user.company && (
                        <TextSm className={classes.company}>
                          {' - ' + review.user.company}
                        </TextSm>
                      )}
                    </Box>
                    <Box className={classes.dateSubmitted}>
                      <TextSm>{`${new Date(review.submitted_at).toLocaleString(
                        'default',
                        {
                          month: 'long',
                        }
                      )} ${new Date(review.submitted_at).toLocaleString(
                        'default',
                        {
                          day: 'numeric',
                        }
                      )}, ${new Date(review.submitted_at).toLocaleString(
                        'default',
                        {
                          year: 'numeric',
                        }
                      )}`}</TextSm>
                    </Box>
                  </Box>
                  {!toggleShow[review.uid] && (
                    <Box className={classes.answer}>
                      <Text className={classes.answerTitle}>
                        {review.answers[0].text}
                      </Text>
                      <TextSm className={classes.answerValue}>
                        {review.answers[0].value.slice(0, 200) + '...'}
                      </TextSm>
                    </Box>
                  )}
                  {toggleShow[review.uid] &&
                    review.answers.map((answer) => (
                      <Box className={classes.answer}>
                        <Text className={classes.answerTitle}>
                          {answer.text}
                        </Text>
                        <TextSm className={classes.answerValue}>
                          {answer.value}
                        </TextSm>
                      </Box>
                    ))}
                  <span
                    onClick={() =>
                      setToggleShow({
                        ...toggleShow,
                        [review.uid]: !toggleShow[review.uid],
                      })
                    }
                  >
                    {toggleShow[review.uid]
                      ? ['Show less', <Icon>ExpandLess</Icon>]
                      : ['Show more', <Icon>ExpandMore</Icon>]}
                  </span>
                </Box>
              </>
            ))}
          </Box>
          <Box className={classes.readMore}>
            <a
              href={crowd_url}
              target="_blank"
              rel="noopener noreferrer"
              title={`${props.name} reviews on G2 Crowd`}
            >
              Read more {props.name} Reviews -
              <img
                src=" https://tracking.g2crowd.com/logo/dark"
                alt="G2 Crowd"
              />
            </a>
          </Box>
          <Box className={classes.writeReviewButton}>
            <Box className={classes.buttonContainer}>
              <a
                href={write_review_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="contained" size="large">
                  Write a review!
                </Button>
              </a>
            </Box>
            <Box className={classes.infoText}>
              By clicking on the button above, you will be redirected to the
              product review webpage on G2 Crowd to write a review of our
              integration partner. By submitting a review to G2 Crowd, you
              consent to the display of your review on Autodesk’s webpages. If
              you choose to submit an a review under your name or username, you
              consent to the display of your name or username on Autodesk’s
              webpages.
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default G2Reviews
