import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { TextSm } from '@system'

const useStyles = makeStyles((theme) => ({
  support: {
    paddingBottom: '40px',
    color: theme.palette.text.tertiary,
  },
  supportText: {
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    paddingBottom: '12px',
  },
  supportDetails: {
    '& p': {
      fontSize: theme.typography.button.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${theme.palette.background.paper}`,
      padding: '12px 0',
      '& span': {
        color: theme.palette.primary.main,
        '& a': {
          textDecoration: 'none',
          color: theme.palette.primary.main,
        },
      },
    },
    '& p:nth-child(3)': {
      '& span': {
        width: '190px',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
}))

const Support = (props) => {
  const classes = useStyles()
  const {
    support: {
      support_email,
      product_website,
      support_phone,
      company: { name },
    },
  } = props
  return (
    <Box className={classes.support}>
      <TextSm className={classes.supportText}>Support:</TextSm>
      <Box className={classes.supportDetails}>
        {name && (
          <TextSm>
            Technology built by
            <span>{name}</span>
          </TextSm>
        )}
        {support_email && (
          <TextSm>
            Email
            <span>
              <a href={`mailto:${support_email}`}>{support_email}</a>
            </span>
          </TextSm>
        )}
        {product_website && (
          <TextSm>
            Partner Website
            <span>
              <a
                href={`${product_website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {product_website
                  .slice(0, 40)
                  .replace('https://', '')
                  .replace('http://', '')}
              </a>
            </span>
          </TextSm>
        )}
        {support_phone && (
          <TextSm>
            Partner Phone
            <span>
              <a href={`tel:+${support_phone.replace(/\D/g, '')}`}>
                {support_phone}
              </a>
            </span>
          </TextSm>
        )}
      </Box>
    </Box>
  )
}

export default Support
